.h5vp_overlay  {
    position: absolute;
    img {        
        max-width: 120px;
        max-height: 120px;
    }
    a {
        text-decoration: none !important;
        cursor: pointer;
    }
    p {
        margin: 0;
        cursor: pointer;
    }
    &.bottom_right {
        bottom: 10px;
        right: 10px;
    }
    &.top_right {
        top: 10px;
        right: 10px;
    }
    &.bottom_left {
        bottom: 10px;
        left: 10px;
    }
    &.top_left {
        top: 10px;
        left: 10px;
    }
}