
.h5vp_player_temp {
    .flex {
      display: flex;
    }
    .justify-start {
      justify-content: start;
    }
    .justify-center {
      justify-content: center;
    }
    .justify-end,
    .justify-right
     {
      justify-content: end;
    }
    &.h5vp_popup_enabled {
        .plyr_wrapper {
          display: none;
        }
        .close {
            display: none;
        }
      }
    .popup_opener {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        .popup_play {
            position: absolute;
            width: 50px;
            fill: #fff;
            cursor: pointer;
        }
    }
    &.h5vp_popup_open {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;
    background: #1f1f1fe3;
    .popup_opener {
      display: none;
    }
    .plyr_wrapper {
        position: absolute;
        display: block;
        width: 900px !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999999;
    }
    .close {
      position: absolute;
      top: 10px;
      right: 20px;
      background: #fff;
      color: #222;
      padding: 1px 7px;
      font-size: 30px;
      line-height: 100%;
      display: inline-block;
      font-family: sans-serif;
      border-radius: 3px;
      cursor: pointer;
    }
  }
}