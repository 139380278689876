.plyr_wrapper .h5vp_end_screen {
    width: 100%;
    height:100%;
    background: #333333e0;
    position: absolute;
    top: 0;
    left: 0;
    display: none;;
    z-index: 999;
    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    .close {
        display: block;
        position: absolute;
        top: 5px;
        right: 10px;
        z-index: 9999;
        color: #222;
        font-size: 35px;
        font-family: sans-serif;
        line-height: 100%;
        cursor: pointer;
        background: #fff;
        line-height: 100%;
        padding: 0px 7px;
        border-radius: 3px;
    }
    p {
        color: #fff;
    }
    a {
        background-color: var(--plyr-video-control-background-hover,var(--plyr-color-main,var(--plyr-color-main,#00b2ff)));
        color: #fff;
        padding: 7px 15px;
        border-radius: 3px;
        text-decoration: none !important;
        font-size: 18px;
    }
}